import deepmerge from "deepmerge";
import {z} from "zod";
import {
    createContext,
    FunctionComponent,
    useCallback,
    useContext,
} from "react";
import {ZodLiteralRecord} from "./zod/literal-key-record";

const fontStyleData = z.object({
    family: z.string(),
    color: z.string(),
    weight: z.string(),
    size: z.number(),
    strokeColor: z.string(),
    strokeWidth: z.number(),
});
export type MapFontStyle = z.infer<typeof fontStyleData>;
const symbolShapeType = z.enum([
    "square",
    "circle",
    "triangle",
    "star"
]);
const symbolStyleData = z.object({
    color: z.string(),
    strokeColor: z.string(),
    strokeWidth: z.number(),
    shape: symbolShapeType,
    size: z.number(),
});
export type MapSymbolStyle = z.infer<typeof symbolStyleData>;
const styleData = z.object({
    font: fontStyleData,
    symbol: symbolStyleData,
});
const partialStyleData = z.object({
    font: fontStyleData.partial(),
    symbol: symbolStyleData.partial(),
}).partial();
export type MapMarkerStyle = z.infer<typeof styleData>;

const landmarkType = z.enum([
    "marker",
    "landmark"
]);
export type LandmarkType = z.infer<typeof landmarkType>;

const stylesData = z.intersection(
    z.object({
        root: styleData,
    }),
    ZodLiteralRecord(landmarkType.options, partialStyleData),
);
export type MapStyles = z.infer<typeof stylesData>;

const landmarkData = z.object({
    name: z.string(),
    type: landmarkType,
    coordinates: z.tuple([z.number(), z.number()]),
    visible: z.boolean(),
    description: z.string().optional(),
    tags: z.string().array().optional(),
});

export type LandmarkData = z.infer<typeof landmarkData>;

export const mapData = z.object({
    title: z.string(),
    minScale: z.number(),
    maxScale: z.number(),
    map: z.string(),
    styles: stylesData,
    landmarks: landmarkData.array(),
});

export type MapData = z.infer<typeof mapData>;

const defaultStyle: MapMarkerStyle = {
    font: {
        family: "serif",
        color: "#000000",
        weight: "regular",
        size: 36,
        strokeColor: "#ffffff",
        strokeWidth: 5,
    },
    symbol: {
        color: "#000000",
        strokeColor: "#ffffff",
        strokeWidth: 3,
        shape: "circle",
        size: 10,
    },
};
const MapStyleContext = createContext<z.infer<typeof stylesData>>({
    root: defaultStyle,
    marker: {},
    landmark: {},
});

export function useMapMarkerStyle(
    type: LandmarkType
): MapMarkerStyle {
    const styles = useContext(MapStyleContext);
    const mergeStyles = useCallback(() => {
        // TODO: handle color string -> hex number conversion in here?
        const stylesForType = styles[type];
        // @ts-expect-error FIXME: pretty sure this is fine, worked in io-ts, but errors in zod
        return (stylesForType ? deepmerge(styles.root, styles[type]) : styles.root) as MapMarkerStyle;
    }, [type, styles]);
    const style = mergeStyles();
    //console.log(style);
    return style;
}

export const MapStyleProvider: FunctionComponent<{ mapData: MapData }> = ({
                                                                              mapData,
                                                                              children,
                                                                          }) => (
    <MapStyleContext.Provider value={mapData.styles}>
        {children}
    </MapStyleContext.Provider>
);
