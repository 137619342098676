import {LandmarkData, LandmarkType, MapData, MapMarkerStyle} from "../../map-data";
import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {mapDataApi} from "../apis/mapApi";

export interface LandmarkMapData extends LandmarkData {
    id: number;
}
export interface MapLandmarksVisibilityState {
    hiddenTypes: string[];
    hiddenTags: string[];
    hiddenIds: number[];
}

export interface MapState extends MapLandmarksVisibilityState {
    highlightedLandmark?: number;
    markerStyles: { root: MapMarkerStyle } & Record<LandmarkType, Partial<MapMarkerStyle>>
    landmarks: LandmarkMapData[];
    data?: MapData;
}

const initialState: MapState = {
    hiddenTypes: [],
    hiddenTags: [],
    hiddenIds: [],
    landmarks: [],
    markerStyles: {
        root: {
            font: {
                family: "serif",
                color: "#000000",
                weight: "regular",
                size: 36,
                strokeColor: "#ffffff",
                strokeWidth: 5,
            },
            symbol: {
                color: "#000000",
                strokeColor: "#ffffff",
                strokeWidth: 3,
                shape: "circle",
                size: 10,
            }
        },
        marker: {},
        landmark: {},
    }
}

const addUniqueElement = <T>(array: T[], item: T) => {
    const set = new Set<T>(array);
    set.add(item);
    return Array.from(set);
}
const removeUniqueElement = <T>(array: T[], item: T) => {
    const set = new Set<T>(array);
    set.delete(item);
    return Array.from(set);
}
const findMarkerByName = (name: string, landmarks: LandmarkMapData[]) =>
    landmarks.find((landmark) => landmark.name === name);
const findMarkerById = (id: number, landmarks: LandmarkMapData[]) =>
    landmarks.find((landmark) => landmark.id === id);
const isLandmarkVisible = ({hiddenIds, hiddenTags, hiddenTypes}: MapLandmarksVisibilityState, landmark: LandmarkMapData) => {
    const {id, tags, type} = landmark;
    if (hiddenIds.includes(id)) {
        return false;
    }
    if (hiddenTypes.includes(type)) {
        return false;
    }
    return tags?.reduce<boolean>((prev, tag) => {
        return prev && hiddenTags.includes(tag)
    }, true) ?? true;
}
const getLandmarkWithVisibility = (state: MapLandmarksVisibilityState, landmark: LandmarkMapData) => ({
    ...landmark,
    visible: isLandmarkVisible(state, landmark),
});

export const mapSlice = createSlice({
    name: "map",
    initialState: initialState as MapState,
    reducers: {
        hideMarkerNamed: (state, {payload}: PayloadAction<string>) => {
            const marker = findMarkerByName(payload, state.landmarks);
            if (marker) {
                state.hiddenIds = addUniqueElement(state.hiddenIds, marker.id);
            }
        },
        showMarkerNamed: (state, {payload}: PayloadAction<string>) => {
            const marker = findMarkerByName(payload, state.landmarks);
            if (marker) {
                state.hiddenIds = removeUniqueElement(state.hiddenIds, marker.id);
            }
        },
        hideMarkerWithId: (state, {payload}: PayloadAction<number>) => {
            state.hiddenIds = addUniqueElement(state.hiddenIds, payload);
        },
        showMarkerWithId: (state, {payload}: PayloadAction<number>) => {
            state.hiddenIds = removeUniqueElement(state.hiddenIds, payload);
        },
        hideMarkersOfType: (state, {payload}: PayloadAction<string>) => {
            state.hiddenTypes = addUniqueElement(state.hiddenTypes, payload);
        },
        showMarkersOfType: (state, {payload}: PayloadAction<string>) => {
            state.hiddenTypes = removeUniqueElement(state.hiddenTypes, payload);
        },
        hideMarkersWithTag: (state, {payload}: PayloadAction<string>) => {
            state.hiddenTags = addUniqueElement(state.hiddenTags, payload);
        },
        showMarkersWithTag: (state, {payload}: PayloadAction<string>) => {
            state.hiddenTags = removeUniqueElement(state.hiddenTags, payload);
        },
        highlightMarkerWithId: (state, {payload}: PayloadAction<number>) => {
            state.highlightedLandmark = payload;
        },
        clearHighlight: (state) => {
            state.highlightedLandmark = undefined;
        },
    },
    extraReducers: (builder) => builder
        .addMatcher(mapDataApi.endpoints.getMapDataByName.matchFulfilled, (state, {payload}) => {
            state.data = payload;
            state.landmarks = payload.landmarks
                // do not add anything marked as not visible in the server response!
                .filter((landmark) => landmark.visible)
                .map((landmark, id) => ({
                    ...landmark,
                    id,
                }));
        })
});

export const {
    hideMarkerNamed,
    hideMarkersOfType,
    hideMarkerWithId,
    hideMarkersWithTag,
    showMarkerNamed,
    showMarkersOfType,
    showMarkerWithId,
    showMarkersWithTag,
    highlightMarkerWithId,
    clearHighlight,
} = mapSlice.actions;

const selectMapStateRaw = (state: RootState) => state.map;
const selectAllLandmarksRaw = (state: RootState): LandmarkMapData[] =>
    state.map.landmarks;
/**
 * Get all landmarks
 */
export const selectAllLandmarks = createSelector(
    selectMapStateRaw,
    selectAllLandmarksRaw,
    (state, landmarks) => landmarks.map((landmark) =>
        getLandmarkWithVisibility(state, landmark))
)
/**
 * Get landmark with a specific name
 * @param name
 */
export const selectLandmarkByName = (name: string) => createSelector(
    selectAllLandmarks,
    (allLandmarks) => allLandmarks.find((landmark) =>
        landmark.name === name)
);
/**
 * Get landmark with a specific id
 * @param id
 */
export const selectLandmarkById = (id: number) => createSelector(
    selectAllLandmarks,
    (allLandmarks) => allLandmarks.find((landmark) =>
        landmark.id === id)
);
/**
 * Get all the ids that have been hidden
 */
export const selectHiddenLandmarkIds = (state: RootState): number[] => state.map.hiddenIds;
/**
 * Get all the types that have been hidden
 */
export const selectHiddenLandmarkTypes = (state: RootState): string[] => state.map.hiddenTypes;
/**
 * Get all the tags that have been hidden
 */
export const selectHiddenLandmarkTags = (state: RootState): string[] => state.map.hiddenTags;
/**
 * Get all landmark types present in the data
 */
export const selectAllLandmarkTypes = createSelector(
    selectAllLandmarks,
    (allLandmarks) => {
        const types = new Set<string>();
        allLandmarks.forEach(({type}) => types.add(type))
        return Array.from(types);
    }
)
/**
 * Get all landmark tags present in the data
 */
export const selectAllLandmarkTags = createSelector(
    selectAllLandmarks,
    (allLandmarks) => {
        const tags = new Set<string>();
        allLandmarks.forEach((landmark) =>
            landmark.tags?.forEach((tag) =>
                tags.add(tag)));
        return Array.from(tags);
    }
)
/**
 * Get all landmarks with a specific tag
 * @param tag
 */
export const selectLandmarksWithTag = (tag: string) => createSelector(
    selectAllLandmarks,
    (allLandmarks) =>
        allLandmarks.filter((landmark) =>
            landmark.tags?.includes(tag))
)


const selectHighlightedLandmarkId = (state: RootState): number | undefined =>
    state.map.highlightedLandmark;
/**
 * Get the current highlighted landmark
 */
export const selectHighlightedLandmark = createSelector(
    selectHighlightedLandmarkId,
    selectAllLandmarks,
    (id, landmarks) => id !== undefined ? findMarkerById(id, landmarks) : undefined
)
/**
 * Get the landmarks visible on the map
 */
export const selectVisibleLandmarks = createSelector(
    selectAllLandmarks,
    (landmarks) => landmarks.filter((landmark) => landmark.visible)
)