import {configureStore} from "@reduxjs/toolkit";
import {mapSlice} from "./slices/mapSlice";
import {mapDataApi} from "./apis/mapApi";

export const store = configureStore({
    reducer: {
        [mapSlice.name]: mapSlice.reducer,
        [mapDataApi.reducerPath]: mapDataApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(
                mapDataApi.middleware
            )
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;