import {FunctionComponent} from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {
    hideMarkerWithId,
    highlightMarkerWithId,
    LandmarkMapData, selectAllLandmarks,
    showMarkerWithId
} from "../redux/slices/mapSlice";
import {ToggleButton} from "./ToggleButton";
import {AiFillEye, AiFillEyeInvisible, FaMapMarkerAlt} from "react-icons/all";
import {Button} from "./Button";
import styled from "styled-components";

const ListItem = styled.li`
  list-style: none;
`

interface LandmarkListItemProps {
    data: LandmarkMapData;
    onLandmarkVisibilityChanged: (landmark: LandmarkMapData, visible: boolean) => void;
    onLandmarkHighlightClicked: (landmark: LandmarkMapData) => void;
}

const LandmarkListItem: FunctionComponent<LandmarkListItemProps> = ({
                                                                        data,
                                                                        onLandmarkHighlightClicked,
                                                                        onLandmarkVisibilityChanged,
                                                                    }) => {
    const onVisibilityChanged = (newValue: boolean) => {
        onLandmarkVisibilityChanged(data, newValue)
    }
    const onHighlightClicked = () => {
        onLandmarkHighlightClicked(data);
    }
    return (
        <ListItem>
            <Button onClick={onHighlightClicked}>
                <FaMapMarkerAlt />
            </Button>
            <ToggleButton
                on={<AiFillEye />}
                off={<AiFillEyeInvisible />}
                value={data.visible}
                onChange={onVisibilityChanged}
            />
            {data.name}
        </ListItem>
    )
}


export interface LandmarkListProps {
    data: LandmarkMapData[];
    onLandmarkVisibilityChanged: (landmark: LandmarkMapData, visible: boolean) => void;
    onLandmarkHighlightClicked: (landmark: LandmarkMapData) => void;
}
export const LandmarkList: FunctionComponent<LandmarkListProps> = ({
                                                                       data,
                                                                       onLandmarkVisibilityChanged,
                                                                       onLandmarkHighlightClicked,
                                                                   }) => {

    return (
        <ul>
            {data.map((landmark) => (
                <LandmarkListItem
                    key={landmark.id}
                    data={landmark}
                    onLandmarkHighlightClicked={onLandmarkHighlightClicked}
                    onLandmarkVisibilityChanged={onLandmarkVisibilityChanged}
                />
            ))}
        </ul>
    )
}

export const LandmarkListWithData: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const landmarks = useAppSelector(selectAllLandmarks);
    const onHighlightLandmark = ({id}: LandmarkMapData) => {
        dispatch(highlightMarkerWithId(id))
    }
    const onVisibilityChanged = ({id}: LandmarkMapData, visible: boolean) => {
        const action = visible ? showMarkerWithId : hideMarkerWithId;
        dispatch(action(id));
    }
    return (
        <LandmarkList
            data={landmarks}
            onLandmarkVisibilityChanged={onVisibilityChanged}
            onLandmarkHighlightClicked={onHighlightLandmark}
        />
    )
}