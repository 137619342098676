import {FunctionComponent, useCallback, useEffect, useState} from "react";
import {Container, Sprite} from "@inlet/react-pixi";
import {BaseTexture, ImageBitmapResource, Texture} from "pixi.js";

export const isPower2 = (x: number) => ((x !== 0) && ((x & (~x + 1)) === x));

export async function createImageBitmapFromUrl(url: string, options?: ImageBitmapOptions) {
    const img = new Image();
    img.src = url;
    // wait for the image to load
    await img.decode();
    return await createImageBitmap(img, options);
}

const getTileCounts = (width: number, height: number, maxTileSize: number) => {
    let remainingWidth = width;
    let remainingHeight = height;
    let tileCountX = 1;
    let tileCountY = 1;
    while (remainingWidth > maxTileSize) {
        tileCountX++;
        remainingWidth -= maxTileSize;
    }
    while (remainingHeight > maxTileSize) {
        tileCountY++;
        remainingHeight -= maxTileSize;
    }
    return [tileCountX, tileCountY];
}
export interface Tile {
    image: ImageBitmap;
    x: number;
    y: number;
}
type TilePromise = Omit<Tile, "image"> & {tilePromise: Promise<ImageBitmap>}
export async function createTilesFromImage(url: string, maxTileSize = 4096) {
    // 5250x3488
    // load image
   const image = await createImageBitmapFromUrl(url);
   // get number of tiles
   const [tilesX, tilesY] = getTileCounts(image.width, image.height, maxTileSize);
   const tiles: TilePromise[] = [];
   // split into number of tiles
    for (let x = 0; x < tilesX; x++) {
        for (let y = 0; y < tilesY; y++) {
            const tilePromise = createImageBitmap(image, x * maxTileSize, y * maxTileSize, maxTileSize, maxTileSize);
            tiles.push({
                tilePromise,
                x: x * maxTileSize,
                y: y * maxTileSize,
            });
        }
    }
    const loadedImages = await Promise.all(tiles.map((tile) => tile.tilePromise));
    return loadedImages.map((image, index) => {
        const {x,y} = tiles[index]
        return {image, x, y};
    });
}

export function useTextureTiles(url: string, maxTileSize = 4096) {
    const [tiles, setTiles] = useState<Tile[]>();
    useEffect(() => {
        //console.log(`useTextureTile(${url}, ${maxTileSize})`)
        let cancelled = false;
        createTilesFromImage(url, maxTileSize).then((tiles) => {
            //console.log(`useTextureTile(${url}, ${maxTileSize}) created tiles:`, tiles.map((tile) => ({x: tile.x, y: tile.y, width: tile.image.width, height: tile.image.height})))
            !cancelled && setTiles(tiles);
        });
        return () => {
            cancelled = true;
        }
    }, [url, maxTileSize]);

    return tiles;
}
export const textureFromImageBitmap = (imageBitmap: ImageBitmap): Texture => {
    return new Texture(new BaseTexture(new ImageBitmapResource(imageBitmap)));
}
export interface LargeSpriteProps {
    src: string;
    maxTileSize?: number;
}
export const LargeSprite: FunctionComponent<LargeSpriteProps> = ({src, maxTileSize= 4096}) => {
    const tiles = useTextureTiles(src, maxTileSize);

    const getTilesWithTextures = useCallback(() => {
        return tiles?.map((tile) => {
            return {
                ...tile,
                texture: textureFromImageBitmap(tile.image),
            }
        })
    }, [tiles]);
    return (
        <Container>
            {getTilesWithTextures()?.map(({texture ,x, y}) => (
                    <Sprite texture={texture} x={x} y={y} key={`${x}-${y}`}/>
                ))
            }
        </Container>
    )
}