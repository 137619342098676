import {InteractionManager, DisplayObject, Point} from "pixi.js";
import {
  Viewport as PixiViewport,
  IViewportOptions,
  IBounceOptions,
  IClampOptions,
  IDragOptions,
  IDecelerateOptions,
  IPinchOptions,
  ISnapOptions,
  IFollowOptions,
  IWheelOptions,
  IAnimateOptions,
  IClampZoomOptions,
  IMouseEdgesOptions,
  ISnapZoomOptions,
} from "pixi-viewport";
import {PixiComponent} from "@inlet/react-pixi";

interface EnsureVisibleOptions {
  x: number;
  y: number;
  width: number;
  height: number;
  resizeToFit?: boolean;
}

interface ViewportPluginProps {
  animate?: IAnimateOptions | false;
  bounce?: IBounceOptions | boolean;
  decelerate?: IDecelerateOptions | boolean;
  drag?: IDragOptions | boolean;
  follow?: (IFollowOptions & {target: DisplayObject}) | false;
  mouseEdges?: IMouseEdgesOptions | false;
  pinch?: IPinchOptions | boolean;
  snap?: ({x: number; y: number} & (ISnapOptions | undefined)) | false;
  snapZoom?: ISnapZoomOptions | boolean;
  wheel?: IWheelOptions | boolean;
}

export interface ViewportProps extends IViewportOptions {
  plugins?: ViewportPluginProps;
  pause?: boolean;
  ensureVisible?: EnsureVisibleOptions;
  center?: Point;
  corner?: Point;
  onMove?: () => void;
  onZoom?: () => void;
}

const setPlugins = (
  viewport: PixiViewport,
  plugins: ViewportPluginProps,
  oldPlugins?: ViewportPluginProps
) => {
  const {
    animate,
    bounce,
    decelerate,
    drag,
    follow,
    mouseEdges,
    pinch,
    snap,
    snapZoom,
    wheel,
  } = plugins;
  if (animate) {
    viewport.animate(animate);
  } else if (oldPlugins?.animate) {
    viewport.plugins.remove("animate");
  }
  if (bounce) {
    viewport.bounce(typeof bounce === "boolean" ? undefined : bounce);
  } else if (oldPlugins?.bounce) {
    viewport.plugins.remove("bounce");
  }
  if (decelerate) {
    viewport.decelerate(
      typeof decelerate === "boolean" ? undefined : decelerate
    );
  } else if (oldPlugins?.decelerate) {
    viewport.plugins.remove("decelerate");
  }
  if (drag) {
    viewport.drag(typeof drag === "boolean" ? undefined : drag);
  } else if (oldPlugins?.drag) {
    viewport.plugins.remove("drag");
  }
  if (follow) {
    const {target, ...options} = follow;
    viewport.follow(target, options);
  } else if (oldPlugins?.follow) {
    viewport.plugins.remove("follow");
  }
  if (mouseEdges) {
    viewport.mouseEdges(mouseEdges);
  } else if (oldPlugins?.mouseEdges) {
    viewport.plugins.remove("mouse-edges");
  }

  if (pinch) {
    viewport.pinch(typeof pinch === "boolean" ? undefined : pinch);
  } else if (oldPlugins?.pinch) {
    viewport.plugins.remove("pinch");
  }
  if (snap) {
    const {x, y, ...options} = snap;
    viewport.snap(x, y, options);
  } else if (oldPlugins?.snap) {
    viewport.plugins.remove("snap");
  }

  if (wheel) {
    viewport.wheel(typeof wheel === "boolean" ? undefined : wheel);
  } else if (oldPlugins?.wheel) {
    viewport.plugins.remove("wheel");
  }
};

const setProps = (
  viewport: PixiViewport,
  props: ViewportProps,
  oldProps?: ViewportProps
) => {
  const {
    plugins,
    pause,
    ensureVisible,
    screenHeight,
    screenWidth,
    worldHeight,
    worldWidth,
    center,
    corner,
      onMove,
      onZoom,
  } = props;
  if (plugins) {
    setPlugins(viewport, plugins, oldProps?.plugins);
  }
  if (pause !== undefined) {
    viewport.pause = pause;
  }
  if (ensureVisible) {
    viewport.ensureVisible(
      ensureVisible.x,
      ensureVisible.y,
      ensureVisible.width,
      ensureVisible.height,
      ensureVisible.resizeToFit
    );
  }
  if (screenHeight) {
    viewport.screenHeight = screenHeight;
  }
  if (screenWidth) {
    viewport.screenWidth = screenWidth;
  }
  if (worldHeight) {
    viewport.worldHeight = worldHeight;
  }
  if (worldWidth) {
    viewport.worldWidth = worldWidth;
  }
  if (center) {
    viewport.center = center;
  }
  if (corner) {
    viewport.corner = corner;
  }

  if (onMove) {
    if (oldProps?.onMove) {
      viewport.off("moved-end", oldProps.onMove)
    }
    viewport.on("moved-end", onMove);
  }

  if (onZoom) {
    if (oldProps?.onZoom) {
      viewport.off("zoomed-end", oldProps.onZoom)
    }
    viewport.on("zoomed-end", onZoom);
  }
};

export const Viewport = PixiComponent<ViewportProps, PixiViewport>("Viewport", {
  create: (props) => {
    // instantiate something and return it
    const {plugins, pause, ensureVisible, ...options} = props;
    const viewport = new PixiViewport(options);

    setProps(viewport, props);
    return viewport;
  },
  didMount: (instance, parent) => {
    // apply custom logic on mount
  },
  willUnmount: (instance, parent) => {
    // clean up before removal
  },
  applyProps: (instance, oldProps, newProps) => {
    // props changed
    // apply logic to the instance
    setProps(instance, newProps, oldProps);
  },
});
