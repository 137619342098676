import {FunctionComponent, useCallback} from "react";
import {Graphics as PixiGraphics} from "pixi.js";
import {Graphics} from "@inlet/react-pixi";
import {MapSymbolStyle} from "../map-data";

export interface MapSymbolProps {
  style: Omit<MapSymbolStyle, "color" | "strokeColor">;
  color: number;
  strokeColor: number;
}

export const MapSymbol: FunctionComponent<MapSymbolProps> = ({style, color, strokeColor}) => {
  const draw = useCallback(
    (g: PixiGraphics) => {
      const {strokeWidth, size, shape} = style;
      const halfSize = size / 2;
      g.beginFill(color).lineStyle(strokeWidth, strokeColor, 1, 1);
      switch (shape) {
        case "circle":
          g.drawCircle(-halfSize, -halfSize, halfSize);
          break;
        case "square":
          g.drawRect(-halfSize, -halfSize, size, size);
          break;
        default:
          throw new Error(`MapSymbol shape ${shape} not implemented`);
      }
      g.endFill();
    },
    [style]
  );
  return <Graphics draw={draw} />;
};
