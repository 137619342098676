import {FunctionComponent} from "react";
import {A} from "hookrouter"

export const MapsPage: FunctionComponent = () => (
    <main>
        Maps:
        <ul>
            <li><A href="maps/baldurs-gate">Baldur's Gate</A></li>
        </ul>
    </main>
);
