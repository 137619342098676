import {FunctionComponent} from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {
    hideMarkersWithTag,
    selectAllLandmarkTags,
    selectHiddenLandmarkTags,
    showMarkersWithTag
} from "../redux/slices/mapSlice";


export interface TagsListProps {
    tags: string[];
    hiddenTags: string[];
    onTagClicked: (tag: string) => void;
}

export const TagsList: FunctionComponent<TagsListProps> = ({
                                                               tags,
                                                               hiddenTags,
                                                               onTagClicked,
                                                           }) => {
    const onClicked = (tag: string) => () => {
        onTagClicked(tag);
    }
    return (
        <div>
            {tags.map((tag) => (
                <button key={tag} onClick={onClicked(tag)}>
                    {tag} {hiddenTags.includes(tag) && "(Hidden)"}
                </button>
            ))}
        </div>
    )
}

export const TagsListWithData: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const allTags = useAppSelector(selectAllLandmarkTags);
    const hiddenTags = useAppSelector(selectHiddenLandmarkTags);
    const onTagVisibilityToggled = (tag: string) => {
        const action = hiddenTags.includes(tag) ? showMarkersWithTag : hideMarkersWithTag;
        dispatch(action);
    }
    return (
        <TagsList
            tags={allTags}
            hiddenTags={hiddenTags}
            onTagClicked={onTagVisibilityToggled}
        />
    )
}