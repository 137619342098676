import {Fragment, FunctionComponent, MouseEvent, useEffect, useRef} from "react";
import {Viewport as PixiViewport} from "pixi-viewport";
import {MapData, MapStyleProvider} from "../map-data";
import {Viewport} from "./pixi/Viewport";
import {Container, Stage} from "@inlet/react-pixi";
import {MapMarker} from "./MapMarker";
import {useWindowSize} from "../hooks/useWindowSize";
import {Point} from "@pixi/math";
import {useAppSelector} from "../redux/hooks";
import {
    LandmarkMapData,
    selectHighlightedLandmark,
    selectVisibleLandmarks
} from "../redux/slices/mapSlice";
import {LargeSprite} from "./pixi/LargeSprite";
import {useGetMapDataByNameQuery} from "../redux/apis/mapApi";
import {MapDetailsPane} from "./MapDetailsPane";

export interface MapViewProps {
    mapData: MapData
    landmarks: LandmarkMapData[];
    highlightedLandmark?: LandmarkMapData;
    debug?: boolean;
}

export const MapView: FunctionComponent<MapViewProps> = ({
                                                             mapData,
                                                             landmarks,
                                                             highlightedLandmark,
                                                             debug,
                                                         }) => {
    const [windowWidth, windowHeight] = useWindowSize();
    const viewportRef = useRef<PixiViewport>(null);

    useEffect(() => {
        if (highlightedLandmark && viewportRef.current) {
            viewportRef.current.center = new Point(...highlightedLandmark.coordinates);
        }
    }, [highlightedLandmark])

    const onMove = () => {
        // console.log(`top: ${viewportRef.current?.top} left: ${viewportRef.current?.left} bottom: ${viewportRef.current?.bottom} right: ${viewportRef.current?.right}`);
    };
    const onZoom = () => {
        // console.log(`zoom: ${viewportRef.current?.scaled}`);
    };
    const onMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
        if (viewportRef.current) {
            const {x, y} = viewportRef.current.toWorld(new Point(e.clientX, e.clientY));
            // console.log(`onMouseMove: ${x},${y}`);
        }
    };
    const onClick = (e: MouseEvent<HTMLCanvasElement>) => {
        if (viewportRef.current) {
            const {x, y} = viewportRef.current.toWorld(new Point(e.clientX, e.clientY));
            console.log(`onClick: ${x},${y}`);
        }
    }
    return (
        <Fragment>
            <Stage
                width={windowWidth}
                height={windowHeight}
                onMouseMove={onMouseMove}
                onClick={onClick}
            >
                <MapStyleProvider mapData={mapData}>
                    <Viewport
                        plugins={{
                            drag: true,
                            wheel: true,
                            pinch: true,
                        }}
                        screenWidth={windowWidth}
                        screenHeight={windowHeight}
                        onMove={onMove}
                        onZoom={onZoom}
                        ref={viewportRef}
                    >
                        <LargeSprite src={mapData.map} />
                        <Container>
                            {landmarks.map(({
                                                name,
                                                type,
                                                coordinates,
                                                visible,
                                                tags,
                                            }, key) => (
                                <MapMarker
                                    coordinates={coordinates}
                                    name={name}
                                    type={type}
                                    visible={visible}
                                    tags={tags}
                                    key={key}
                                />
                            ))}
                        </Container>
                    </Viewport>
                </MapStyleProvider>
            </Stage>
            <MapDetailsPane />
        </Fragment>
    );
};

export interface MapViewWithDataLoadingProps {
    map: string;
    onMapDataLoaded?: (data: MapData) => void;
    debug?: boolean;
}

export const MapViewWithDataLoading: FunctionComponent<MapViewWithDataLoadingProps> = ({map, onMapDataLoaded,  debug}) => {
    const {data, error, isLoading} = useGetMapDataByNameQuery(map);
    const landmarkData = useAppSelector(selectVisibleLandmarks);
    const highlightedLandmark = useAppSelector(selectHighlightedLandmark);
    useEffect(() => {
        data && onMapDataLoaded?.(data);
    }, [data]);
    return data
        ? (
            <MapView
                mapData={data}
                landmarks={landmarkData}
                highlightedLandmark={highlightedLandmark}
                debug={debug}
            />
        ) : isLoading
            ? (
                <div>
                    Loading...
                </div>
            ) : error
                ? (
                    <div>
                        {JSON.stringify(error)}
                    </div>
                ) : null;
};
