import {FunctionComponent} from "react";
import {LandmarkData, useMapMarkerStyle} from "../map-data";
import {Container, Text} from "@inlet/react-pixi";
import {utils} from "pixi.js";
import {MapSymbol} from "./MapSymbol";

export const MapMarker: FunctionComponent<LandmarkData> = ({
  name,
  type,
  coordinates,
}) => {
  const [x, y] = coordinates;
  const {symbol, font} = useMapMarkerStyle(type);
  return (
    <Container x={x} y={y}>
      <MapSymbol
          style={symbol}
          color={utils.string2hex(symbol.color)}
          strokeColor={utils.string2hex(symbol.strokeColor)} />
      <Text
          x={symbol.size}
          y={-font.size / 2}
        text={name}
        style={{
            textBaseline: "bottom",
          fill: utils.string2hex(font.color),
          fontFamily: font.family,
          fontSize: font.size,
          //fontWeight: font.weight,
          stroke: utils.string2hex(font.strokeColor),
          strokeThickness: font.strokeWidth,
        }}
      />
    </Container>
  );
};
