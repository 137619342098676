import {FunctionComponent} from "react";
import {MapViewWithDataLoading} from "../components/MapView";
import {MapData} from "../map-data";

export interface MapPageProps {
  map: string;
}
export const MapViewPage: FunctionComponent<MapPageProps> = ({map}) => {
    const onMapLoaded = ({title}: MapData) => {
        // react-helmet would be a better idea, but having issues getting it working correctly
       document.title = title;
    }
    return (
        <main>
            <MapViewWithDataLoading
                map={map}
                onMapDataLoaded={onMapLoaded}
            />
        </main>
    );
}
