import {FunctionComponent, UIEvent, useEffect, useState} from "react";
import {LandmarkListWithData} from "./LandmarkList";
import {TagsListWithData} from "./TagsList";
import styled from "styled-components";

const InfoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  max-height: 100%;
  overflow: auto;
`

const InfoPanel = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  touch-action: pan-y;
  overflow-y: auto;
`

export const MapDetailsPane: FunctionComponent = () => {
    const [visible, setVisible] = useState(false);
    const onPanelVisibilityClicked = () => setVisible(!visible);
    const onPanelScroll = (e: UIEvent) => {
        console.log("panel scroll event!")
        e.stopPropagation();
    }
    return (
        <InfoContainer>
            {visible && (
                <InfoPanel onScroll={onPanelScroll} >
                    <TagsListWithData/>
                    <LandmarkListWithData/>
                </InfoPanel>
            )}
            <button onClick={onPanelVisibilityClicked}>
                {visible ? ">" : "<"}
            </button>
        </InfoContainer>
    )
}
