import React from "react";
import {useRoutes, HookRouter, setBasepath} from "hookrouter";
import {HomePage} from "./routes/HomePage";
import {MapViewPage} from "./routes/MapViewPage";
import {NotFoundPage} from "./routes/NotFoundPage";
import {MapsPage} from "./routes/MapsPage";
import "./App.css";

const routes: HookRouter.RouteObject = {
  //"/": () => <HomePage />,
  "/": () => <MapViewPage map={"baldurs-gate"} />,
  "/maps": () => <MapsPage />,
  "/maps/:map": ({map}: HookRouter.QueryParams) => <MapViewPage map={map} />,
};

function App() {
  const routeResult = useRoutes(routes);
  return routeResult || <NotFoundPage />;
}

export default App;
