import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {mapData, MapData} from "../../map-data";

export const mapDataApi = createApi({
    reducerPath: 'mapDataApi',
    baseQuery: fetchBaseQuery({ baseUrl: './data/' }),
    endpoints: (builder) => ({
        getMapDataByName: builder.query<MapData, string>({
            query: (name) => `${name}/map.json`,
            transformResponse: (baseQueryReturnValue) => {
                return mapData.parse(baseQueryReturnValue);
            }
        }),
    }),
});

export const {useGetMapDataByNameQuery} = mapDataApi;
