import {FunctionComponent} from "react";
import {Button} from "./Button";

export interface ToggleButtonProps {
    on: JSX.Element;
    off: JSX.Element;
    value: boolean;
    onChange: (newValue: boolean) => void;
    className?: string;
}

export const ToggleButton: FunctionComponent<ToggleButtonProps> = ({
                                                                       on,
                                                                       off,
                                                                       value,
                                                                       onChange,
                                                                       className,
                                                                   }) => {
    const onClick = () => {
        onChange(!value);
    }
    return (
        <Button data-value={value}
                className={className}
                onClick={onClick}
        >
            {value ? on : off}
        </Button>
    )
}