import {useCallback, useEffect, useState} from "react";

export function useWindowSize() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const onResize = useCallback(() => {
        console.log(`onResize: ${window.innerWidth}x${window.innerHeight}`);
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }, []);
    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [onResize]);

    return [width, height];
}

export function useWindowHeight() {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    useEffect(() => {
        const onResize = () => setWindowHeight(window.innerHeight);
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);
    return windowHeight;
}